<button
  type="button"
  class="op-ian-bell op-app-menu--item-action"
  (click)="openCenter($event)"
  >
  <op-icon icon-classes="icon-bell">
  </op-icon>
  <ng-container *ngIf="(unreadCount$ | async) as unreadCount">
  <span
      *ngIf="unreadCount > 0"
      class="op-ian-bell--indicator"
      data-qa-selector="op-ian-notifications-count"
      [textContent]="unreadCount > 99 ? '' : unreadCount">
  </span>
  </ng-container>
</button>